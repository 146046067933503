import React, { useState } from 'react'
import koreanFlag from '../assets/flags/south-korea-flag-icon-256.png'
import russianFlag from '../assets/flags/russia-flag-icon-256.png'
import usFlag from '../assets/flags/united-states-of-america-flag-icon-256.png'

const LanguageSelector = ({selectLanguage, isLanguageSelected}) => {
    const [isVisible, setIsVisible] = useState(true);
    const [isClicked, setIsClicked] = useState(true);


    const LanguageSelectorStyle = {
        backgroundColor: '#11130C',
        background: '#11130C',
        color: '#F5FFB9',
        listStyleType: 'none',
        height: '100vh',
        width: '100vw',
        top: '0%',
        position: 'fixed',
        overflow: 'auto',
        // Transition settings:
        transition: 'opacity 0.5s'
    }

    const contentStyling = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -60%)',
        fontWeight: 'bold',
        // zIndex: '999',

    }

    const flagGroupStyling = {
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1em',
            listStyleType: 'none',
            padding:'0',
    }

    // Animation (Visibility)
    const visible = {
        opacity: '1',
    }

    const hidden = {
        opacity: '0'
    }



    const handleLanguage = (parentLanguage) => {
        const selectedLanguage = parentLanguage;
        selectLanguage(selectedLanguage);
        window.location.href = '#home';

        // Hiding the component
        setIsVisible(false);
        setIsClicked(true);
        isLanguageSelected(isClicked);
    };

    const flagStyling = {
        backgroundSize: '100% 100%',
        width: '170px',
        height: '110px',
        cursor: 'pointer',
    }


    return (
        <div style={{

            ...LanguageSelectorStyle,
            ...(!isVisible ? hidden : visible),
            zIndex: '1000',
        }}
        >

            <div style={contentStyling}>
                {/* <p>Choose your language</p>
                <p>언어를 선택하세요</p>
                <p>Выберите язык</p> */}

                <ul style ={flagGroupStyling}> 

                    <li key='kr'>
                        <div style={{
                            backgroundImage: `url(${koreanFlag})`,
                            ...flagStyling
                        }} 
                        onClick={()=> handleLanguage('korean')}
                        />
                    </li>

                    <li key='ru'>
                        <div style={{
                            backgroundImage: `url(${russianFlag})`,
                            ...flagStyling
                        }} 
                        onClick={()=> handleLanguage('russian')}
                        />
                    </li>
                    
                    <li key='en'>
                        <div style={{
                            backgroundImage: `url(${usFlag})`,
                            ...flagStyling
                        }} 
                        onClick={()=> handleLanguage('english')}
                        />
                    </li>

                    {/* <button onClick={() => Cookies.remove('language')}>Remove Cookies</button> */}
                    
                </ul>
            </div>
        </div>
    )
}

export default LanguageSelector;