// import logo from './logo.svg';
import Cookies from 'js-cookie';
import './App.css';
import LanguageSelector from './components/LanguageSelector';
import { useEffect, useState } from 'react';
import { EnglishContent } from './components/language/EnglishContent';
import { RussianContent } from './components/language/RussianContent';
import { KoreanContent } from './components/language/KoreanContent';
import MainSection from './components/sections/MainSection';
import AboutUs from './components/sections/AboutUs';
import GallerySection from './components/sections/GallerySection'
import LocationSection from './components/sections/LocationSection';
import MarriageAdviceSection from './components/sections/MarriageAdviceSection';
import MessageSection from './components/sections/MessageSection';
import Footer from './components/sections/Footer';

function App() {
  const [language, setLanguage] = useState(Cookies.get('language') || 'english')
  const [languageContent, setLanguageContent] = useState(EnglishContent)
  const [showLanguageSelector, setShowLanguageSelector] = useState(true)
  const [isLanguageSelected, setIsLanguageSelected] = useState(false);

  useEffect(()=> {
    Cookies.set('language', language);
  }, [language])

  const handleTriggerForHeader = (boolean) => {
    console.log(boolean);
    setIsLanguageSelected(boolean);
  }
  
  const handleLanguageSelection = (stateValue) => {
    
    setLanguage(stateValue)
    
    switch(stateValue) {
      case 'english':
        setLanguageContent(EnglishContent)
        break;
      case 'russian':
        setLanguageContent(RussianContent);
        break;
      case 'korean':
        setLanguageContent(KoreanContent);
        break;

      default:
        setLanguageContent(EnglishContent);
        break;
    }

    setTimeout(() => {
      setShowLanguageSelector(false)
    }, 600)
  }


  return (
      <div className="App" style={{ overflowX: 'hidden' }}>
        <div className="content-menu-wrapper">

          <div className="content">
            <MainSection id="home" languageContent={languageContent} isLanguageSelected={isLanguageSelected}/>
            
            <AboutUs id="aboutUs" languageContent={languageContent}/>
            <GallerySection id="gallery"/>
            <LocationSection id='location' languageContent={languageContent}/>
            <MarriageAdviceSection id='marriageAdvice' languageContent={languageContent}/>
            <MessageSection id='message' languageContent={languageContent}/>

            <Footer />
          </div>
          {/* <div className="l-screen-menu-wrapper"> */}
            {/* <div className="l-screen-menu"> */}
              {/* <LargeMenu languageContent={languageContent}/> */}
            {/* </div> */}
          {/* </div> */}


        </div>

        
        
        {showLanguageSelector && <LanguageSelector selectLanguage={handleLanguageSelection} isLanguageSelected={handleTriggerForHeader} />}
      </div>
  );
}


export default App;
