export const KoreanContent = {
    main_date: "2023년 10월 28일",
    main_time: "오후 5시",
    main_button: "위치 보기",
    home_title: '메인',
    about_us_title: "자기소개",
    gallery_title: "갤러리",
    location_title: "위치",
    marriage_advice_title: "결혼 조언",
    message_title: "메시지",
    marriage_advice_text_1: "부부로서의 여정에 있어 타인의 지혜와 조언이 더 큰 힘이 될 수 있다고 믿습니다. 이 웹사이트는 그러한 여러분의 삶의 소중한 지혜, 직접 경험담, 그리고 신혼부부에게 전하고 싶은 따뜻한 조언들을 공유하실 수 있는 장입니다.",
    marriage_advice_text_2: "본 웹사이트에 메시지를 올려주실 때 긍정적이고 존중의 마음으로 나눠 주신다면 진심으로 감사 드리겠습니다.",
    messagefromus_1: "친애하는 가족과 친지 여러분,",
    messagefromus_2: "저희 인생에서 가장 중요하고 소중한 순간을 함께하길 바랍니다. 바쁘신 중에도 귀한 시간을 내어 주신다면, 저희의 결혼예식 분위기는 더욱 특별할 것입니다. 오랜 시간을 함께한 가족과 친지들의 얼굴을 보며 행복을 나누고 싶습니다.",
    messagefromus_3: "여러분께서 함께 한 시간은 저희에게 큰 의미가 있을 것입니다.",
    messagefromus_4: "부디 귀한 걸음 하시어 축복해주시면 감사하겠습니다!",

    location_section: {
        kakaomap: "카카오맵",
        navermap: "네이버지도",
        tmap: "티맵",
        address_title: "주소: ",
        website_title: "웹사이트: "
    },

    aboutus_section: {
        text1: "저희의 첫 만남은 호산나하나로교회에서 시작되었어요. 일요일마다 교회에서 눈을 마주쳤지만, 서로의 마음을 감추려 노력했습니다.",
        text2: "그렇게 3년이 흘러, 첫 데이트는 저희 두 사람의 관계에 큰 변화를 가져왔습니다. 운명처럼 느껴진 그 순간부터 우리는 서로를 놓치지 않기로 했습니다. 아직 함께한 지 얼마 되지 않았지만, 그 안에서도 많은 행복과 도전을 함께 해 왔습니다.",
        text3: "5월 15일, 예비 신랑은 신부에게 프로포즈를 했어요. 그때부터 저희 인생의 새로운 이야기가 시작된 것이죠. 이후로도 여러 난관이 있었지만, 항상 서로의 손을 꽉 잡고 나아가고 있습니다.",
        groom_title: "신랑",
        groom_name: "유남진",
        bride_title: "신부",
        bride_name: "김야나",
    },

    marriage_advice: {
        success: '메시지 발송되었습니다.',
        error: '오류. 개발자에게 문의하시기 바랍니다.',
        submit: '발송',

        nameenter: '이름을 입력해 주세요',
        messageenter: '메시지를 입력해 주세요'
    }
}