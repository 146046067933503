import React, { useEffect } from "react";
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import '../styles/Gallery.css';
import 'aos/dist/aos.css';
import Aos from 'aos';

import gallery1small from '../../assets/photos/gallery_1-small.jpg'
import gallery2small from '../../assets/photos/gallery_2-small.jpeg'
import gallery3small from '../../assets/photos/gallery_3-small.jpg'
import gallery4small from '../../assets/photos/gallery_4-small.jpg'
import gallery5small from '../../assets/photos/gallery_5-small.jpeg'
import gallery6small from '../../assets/photos/gallery_6-small.jpg'
import gallery7small from '../../assets/photos/gallery_7-small.jpg'
import gallery8small from '../../assets/photos/gallery_8-small.jpg'

import gallery1full from '../../assets/photos/gallery_1-full.jpg'
import gallery2full from '../../assets/photos/gallery_2-full.jpeg'
import gallery3full from '../../assets/photos/gallery_3-full.jpg'
import gallery4full from '../../assets/photos/gallery_4-full.jpg'
import gallery5full from '../../assets/photos/gallery_5-full.jpeg'
import gallery6full from '../../assets/photos/gallery_6-full.jpg'
import gallery7full from '../../assets/photos/gallery_7-full.jpg'
import gallery8full from '../../assets/photos/gallery_8-full.jpg'

const GallerySection = () => {
    const onInit= () => {
        // console.log('lightGallery has been initialized')
    }

    useEffect(() => {
        Aos.init()
    }, []);
    
    return (
        <section id="gallery" className="gallery-main-wrapper">
            <div className="gallery-decoration" />
            <div className="gallery-content-wrapper">
                <div className="gallery_head"> 
                    <h2 className='gallery_title'>Gallery</h2>
                    <div className='gallery_line'/>
                </div>

                <div data-aos="fade-up" data-aos-duration='1000'>
                    <LightGallery
                        style="display: flex;"
                        onInit={onInit}
                        speed={500}
                        plugins={[lgThumbnail, lgZoom]}
                        mode='lg-fade'
                        
                    >
                        <a className="gallery-item" data-src={gallery1full}>
                            <img className="img-responsive" alt="" src={gallery1small}/>
                        </a>
                        <a className="gallery-item" href={gallery2full}>
                            <img className="img-responsive"  alt="" src={gallery2small}/>
                        </a>
                        <a className="gallery-item" href={gallery3full}>
                            <img className="img-responsive"alt="" src={gallery3small}/>
                        </a>
                        <a className="gallery-item" data-src={gallery4full}>
                            <img className="img-responsive" alt="" src={gallery4small}/>
                        </a>
                        <a className="gallery-item sm-hide" href={gallery5full}>
                            <img className="img-responsive"  alt="" src={gallery5small}/>
                        </a>
                        <a className="gallery-item sm-hide" href={gallery6full}>
                            <img className="img-responsive"alt="" src={gallery6small}/>
                        </a>
                        <a className="gallery-item sm-hide" data-src={gallery7full}>
                            <img className="img-responsive" alt="" src={gallery7small}/>
                        </a>
                        <a className="gallery-item sm-hide" href={gallery8full}>
                            <img className="img-responsive"  alt="" src={gallery8small}/>
                        </a>
                    </LightGallery>

                </div>
                
            </div>
        </section>
    )
}

export default GallerySection