export const EnglishContent = {
    main_date: "October 28, 2023",
    main_time: "5 PM KST",
    main_button: "See Location",
    home_title: 'Home',
    about_us_title: "About Us",
    gallery_title: "Gallery",
    location_title: "Location",
    marriage_advice_title: "Marriage Advice (Contest)",
    message_title: "Message",
    marriage_advice_text_1: "We believe that wisdom and guidance from others can play a valuable role in a couple’s journey together. This is a space where you can share your pearls of wisdom, personal experiences, and heartfelt advice for newlyweds.",
    marriage_advice_text_2: "Please note that all messages shared on this platform are carefully reviewed and curated to ensure a positive and respectful environment for everyone. We appreciate your understanding and cooperation in adhering to these guidelines.",
    messagefromus_1: "Dear friends and loved ones,",
    messagefromus_2: "We are honored to invite you to be a part of one of the most significant and touching moments of our lives.",
    messagefromus_3: "Your presence will add a special ambiance to this day, and we will be overjoyed to see each of you.",
    messagefromus_4: "Thank you for being with us!",

    location_section: {
        kakaomap: "KAKAO MAP",
        navermap: "NAVER MAP",
        tmap: "T MAP",
        address_title: "Address: ",
        website_title: "Website: "
    },

    aboutus_section: {
        text1: "In the heart of Osanna Hanaro Church, our extraordinary love story found its beginning. Sunday services became our rendezvous, a place where glances intertwined but feelings remained unspoken.",
        text2: "Three years later, a single date changed everything — we realized destiny had united us for a reason. Although we embarked on our journey together just a year ago, it has been filled with moments of happiness, trials, and unwavering faith in our shared future.",
        text3: "On May 15th, Mark (Yu Nam Din) proposed to Jane (Kim Iana), heralding a new chapter in their love story. Over this transformative year, we've weathered many storms, yet we've firmly committed to walking hand in hand, overcoming all obstacles on the path to happiness.",
        groom_title: "Groom",
        groom_name: "Yu Nam Din (Mark)",
        bride_title: "Bride",
        bride_name: "Kim Iana (Jane)",
    },

    marriage_advice: {
        success: 'Message has been sent successfully.',
        error: 'Error. Please Contact Developer',
        submit: 'Submit',

        nameenter: 'Enter your name',
        messageenter: 'Leave your marriage advice here'
    }
}