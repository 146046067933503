import React, { useEffect } from "react";
import { Map, MapMarker } from 'react-kakao-maps-sdk'
import '../styles/LocationSection.css'
import KakaoMapComponent from "./KakaoMapComponent";
import Aos from "aos";
import 'aos/dist/aos.css'
import dotenv from 'dotenv'
import commodoreHotelImage from '../../assets/photos/commodorehotel-normal.jpeg'

dotenv.config();


const LocationSection = ({languageContent}) => {
    
    useEffect(() => {
        Aos.init();
    }, [])


    return (
        <section id="location" style={{position: "relative"}}>
            <div className="location_decoration"/>
            <div className="location_content_section">

                <div className="location_head">
                    <div className='location_line'/>
                    <h2 className='location_title'>Location</h2>
                </div>


                <div className="location_content">
                    <div className="location-background" data-aos="fade-left" data-aos-duration='1000'>
                        <span>Commodore Hotel</span>
                    </div>
                    <div className="location-photo" data-aos="fade-right" data-aos-duration='1000'>
                        <img className='location-photo-img' src={commodoreHotelImage}/>
                    </div>
                </div>

                <hr />
                <div className='location_map-wrapper'style={{margin: '0 2em'}}>
                    {/* <KakaoMapComponent style={{width: '100px', height: '200px', flex: 1, position: 'relative'}}/> */}
                    <Map
                        apiKey={process.env.KAKAO_SECRET}
                        center={{ lat: 35.109188, lng: 129.035462 }}
                        className="location_map"
                        level={4}
                    >
                        <MapMarker position={{ lat: 35.109188, lng: 129.035462 }}></MapMarker>
                    </Map>
                    <div className='location-address'>
                        
                        <div>
                            <span><b>코모도호텔 (Commodore Hotel)</b></span> 
                        </div>
                        <div>
                            <span>{languageContent.location_section.address_title}부산 중구 중구로 151, 1층 (웨딩홀)</span>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', gap: '4px'}}>
                            <span>{languageContent.location_section.website_title}</span> 
                            <a href="https://www.commodore.co.kr/html/main/"><u>commodore.co.kr</u></a>
                        </div>
                        <div className='location_map_button-wrapper' >
                            <button className="map_button">
                                <div className="map_button-pin-icon"/>
                                <a href="http://kko.to/9wDeTzQEQY">
                                    <b>{languageContent.location_section.kakaomap}</b>
                                </a>
                                
                            </button>

                            <button className="map_button">
                                <div className="map_button-pin-icon"/>
                                <a href="https://naver.me/GmVLqTEx">
                                    <b>{languageContent.location_section.navermap}</b>
                                </a>
                                
                            </button>

                            <button className="map_button">
                                <div className="map_button-pin-icon"/>
                                <a href="https://surl.tmobiapi.com/364869ef">
                                    <b>{languageContent.location_section.tmap}</b>
                                </a>
                                
                            </button>
                        </div>

                    </div>

                </div>
            </div>
        </section>
    )
}

export default LocationSection;
