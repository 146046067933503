import React ,{useState, useEffect} from "react";
import '../styles/MainSection.css'
import '../../assets/svg/headButton.svg';

const MainSection = ({languageContent, isLanguageSelected}) => {
    useEffect(() => {
        if (isLanguageSelected) {
            setTimeout(()=>{
                document.getElementById('word_div_editable').classList.remove('hidden');
            },400)

            setTimeout(()=>{
                document.getElementById('main_date_button').classList.remove('hidden');
            },1000)
        }
        
        
    }, [isLanguageSelected]);

    return (
        <section id='home' className="main">

            <div className="background-div">
                <div className="background-photo"/>
                <div className="background-color"/>
                <div className="background-black"/>
                <div className="background-gradient"/>
                
                
                <div className="word_div">
                    <div id="word_div_editable" className='hidden'>
                        
                        <h1 className="mark_main" style={
                            {
                            }
                        }>
                            Mark Yu
                        </h1>

                        <h2 className="and_symbol" style={
                            {
                                fontFamily: 'Niconne', fontSize: '2rem'
                            }
                        }>
                            &
                        </h2>

                        <h1 className="jane_main" style={
                            {

                            }
                        }>
                            Jane Kim
                        </h1>
                    </div>
                    <div id="main_date_button" className="hidden">
                        <p>{languageContent.main_date}<br/>{languageContent.main_time}</p>
                        <button onClick={() => { window.location.href = '#location' }} className="main_location_button">{languageContent.main_button}</button>
                            <div onClick={()=> { window.location.href = '#aboutUs'}} className="main_scrolldown_sign">
                                <p className="main_scrolldown_text">Scroll Down</p>
                                <div className="main_scrolldown_arrow"></div>
                            </div>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default MainSection