import React from 'react';
import '../styles/MessageSection.css'


const MessageSection = ({languageContent}) => {
    return (
        <section id='message'>
            <div className="message-content-wrapper">
                <div className="message_head">
                    <div className='message_line'/>
                    <h2 className='message_title'>Message From Us</h2>
                </div>
                
                <div className="message-border">
                    <div className="message_main">
                        <p><b>{languageContent.messagefromus_1}</b></p>
                        <br/>
                        <p>{languageContent.messagefromus_2}</p>
                        <br/>
                        <p>{languageContent.messagefromus_3}</p>
                        <br/>
                        <p>{languageContent.messagefromus_4}</p>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default MessageSection;