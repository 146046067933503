import React from 'react'
import '../styles/Footer.css'

function Footer() {
    return (
        <footer className="footer-section">
            <div className="footer-content">
            
            
            <button className="footer-mailbutton"><a href="mailto:contact@janemarkwedding.com">Contact Us</a></button>
            <div className='footer-line'/>
        
            <span className="footer-devname">developed and designed by <a id="groommail" href="mailto:kidae4u@gmail.com"><u>mark</u></a></span>
            <span>2023</span>
                    
            </div>
        </footer>
    )
}

export default Footer