import React from "react";
import '../styles/MarriageAdviceSection.css';
import Aos from "aos";
import 'aos/dist/aos.css';
import emailjs from 'emailjs-com';
import dotenv from 'dotenv';

dotenv.config();


const MarriageAdviceSection = ({languageContent}) => {

    
    const sendEmail = (e) => {
        dotenv.config();
        const emailjskey = process.env.REACT_APP_EMAILJS_KEY;
        

        e.preventDefault();
        
        emailjs.sendForm('service_j7vpcuj','template_c2idkyw', e.target, emailjskey)
        .then((result) => {
            alert(languageContent.marriage_advice.success)
        },
        (error) => {
            alert(languageContent.marriage_advice.error)
        });
        e.target.reset();
    }
    
    dotenv.config();
    return (
        <section id="marriageAdvice" className="marriage-advice-section-wrapper">
            <div className="marriageadvice-background"></div>
            
            <div className="marriageadvice-content-wrapper">
                <div className="marriageadvice_head">
                    <h2 className='marriageadvice_title'>Marriage Advice</h2>
                    <div className='marriageadvice_line'/>
                </div>

                <div className="marriageadvice-main-content" data-aos="fade-up" data-aos-duration="1000">
                    <div className='marriageadvice-messagefromus'>
                        <p>
                            {languageContent.marriage_advice_text_1}
                            <br />
                            <br />
                            {languageContent.marriage_advice_text_2}
                        </p>
                    </div>

                    <div className='marriageadvice-form-wrapper'>
                        <form id='adviceForm' onSubmit={sendEmail}>
                            <input className="form-name" type="text" name="name" placeholder={languageContent.marriage_advice.nameenter}/>
                            <textarea className='form-advice' placeholder={languageContent.marriage_advice.messageenter} name="message"></textarea>
                            <input className="form-button" type="submit" value={languageContent.marriage_advice.submit}/>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MarriageAdviceSection;