export const RussianContent = {
    main_date: "28 Октября",
    main_time: "17:00 (KST)",
    main_button: "Локация",
    marriage_advice_text_1: "Мы верим, что мудрость и рекомендации других могут играть важную роль в совместной жизни пары. Это место, где вы можете поделиться своими жемчужинами мудрости, личным опытом и искренними советами для молодожёнов.",
    marriage_advice_text_2: "Обратите внимание, что все сообщения на этой платформе тщательно рассматриваются и отбираются, чтобы обеспечить положительную и уважительную атмосферу для всех. Мы ценим ваше понимание и сотрудничество в соблюдении этих рекомендаций.",
    messagefromus_1: "Дорогие друзья и близкие!",
    messagefromus_2: "Мы рады пригласить вас стать частью одного из самых важных и трогательных моментов в нашей жизни.",
    messagefromus_3: "Ваше присутствие добавит особую атмосферу этому дню, и мы будем безмерно рады видеть каждого из вас.",
    messagefromus_4: "Спасибо, что вы с нами!",

    location_section: {
        kakaomap: "KAKAO MAP",
        navermap: "NAVER MAP",
        tmap: "T MAP",
        address_title: "Адрес: ",
        website_title: "Вебсайт: "
    },

    aboutus_section: {
        text1: "В сердце церкви Осанна Ханаро началась наша удивительная история. Воскресные служения стали местом наших встреч, где взгляды переплетались, но чувства оставались несказанными.",
        text2: "Три года позднее, одно свидание изменило всё — поняли, что судьба свела нас не случайно. Хотя наш путь вместе начался всего год назад, он был наполнен моментами счастья, испытаниями и непоколебимой верой в наше общее будущее.",
        text3: "15 мая Марк протянул Яне кольцо, предвещая новый этап их истории.За этот год вместе нам довелось пережить многое, но мы твердо решили идти рука об руку, стойко преодолевая все преграды на пути к счастью.",
        groom_title: "Жених",
        groom_name: "Ю Нам Дин (Марк)",
        bride_title: "Невеста",
        bride_name: "Ким Яна",
    },

    marriage_advice: {
        success: 'Сообщение отправлено.',
        error: 'Ошибка. Пожалуйста обратитесь к разработчику.',
        submit: 'Отправить',

        nameenter: 'Введите свое имя',
        messageenter: 'Оставьте ваше сообщение здесь'
    },

}