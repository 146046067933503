import React, { useEffect } from 'react';
import '../styles/AboutUs.css';
import Aos from 'aos';
import 'aos/dist/aos.css'

const AboutUs = ({languageContent}) => {

    useEffect(() => {
        Aos.init();
    }, [])
    return (
        <section id="aboutUs" className="about-us_section-wrapper">
            <div className="about-us_floral-decoration"/>
            <div className="about-us_div-wrapper">
                <div className="about-us_head">
                    <div className='about-us_line'/>
                    <h2 className='about-us_title'>About Us</h2>
                </div>

                <div className='about-us_main-content'>
                    <div className='about-us_first-flex'  data-aos="fade-up" data-aos-duration='1000' >

                        {/* <div className='about-us_first-photo'> */}
                            {/* <img className='about-us_1pht' src={require("../../assets/photos/aboutUsPhoto-1-edit.png")}></img> */}
                            {/* <img className='about-us_2pht' src={require("../../assets/photos/aboutUsPhoto-2-edit.png")}></img> */}
                        {/* </div> */}

                        <div className='about-us_first-text'>
                            <p>
                            {languageContent.aboutus_section.text1}
                            <br/><br/>
                            {languageContent.aboutus_section.text2}
                            <br/><br/>
                            {languageContent.aboutus_section.text3}
                            </p>
                        </div>

                        <div className='about-us_info'>
                            <div className='about-us_card'>
                            <span><b>{languageContent.aboutus_section.groom_title}</b></span>
                            {/* <br/> */}
                            <span>{languageContent.aboutus_section.groom_name}</span>
                            </div>

                            <div className='about-us_card'>
                            <span><b>{languageContent.aboutus_section.bride_title}</b></span>
                            {/* <br/> */}
                            <span>{languageContent.aboutus_section.bride_name}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutUs;