import React from 'react';
import './LoadingWheel.css';

function LoadingIndicator() {
    return (
    <div className="loading-wheel-container">
        LOADING ....
        <div className="loading-wheel"></div>
    </div>
    )
}

export default LoadingIndicator;
